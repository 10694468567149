// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

exports.data = () => import("/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/.cache/data.json")

