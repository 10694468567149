module.exports = [{
      plugin: require('/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-65420021-1"},
    },{
      plugin: require('/Users/amaliaviti/Dropbox/projects/aamaliaa.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
